@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

:root {
  --primary: #df731a;
  --primary-dark: #da5e0c;
  --primary-light: rgb(245, 158, 46);
  --secondary: #111111;
  --secondary-dark: black;
  --secondary-light: #151515;
  --light: #DDDDDD;
  background: var(--secondary-dark) !important;
  color: var(--light);
  margin-top: 4rem;
  font-family: Montserrat;
}

body {
  margin: 0;
  padding: 0;
}

.bold {
  margin: 0.5rem 0 !important;
  font-weight: 700 !important;
}

.normal {
  font-weight: 400 !important;
}

.light {
  font-weight: 100 !important;
}

.primary-color {
  color: var(--primary) !important;
}

.primary-background {
  background-color: var(--primary) !important;
}

.jumbotron {
  background:linear-gradient(0.25turn, var(--primary-light), var(--primary-dark));
  display: flex;
  flex-grow: 1;
  height: 20rem;
  justify-content: center;
  align-items: center;
}

.card {
  color: var(--light) !important;
  background: var(--secondary) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

#space {
  display: flex;
  flex-grow: 1;
  height: 6rem;
}